.App {
  /* background-color: #DDDDDD; */
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  font-family: 'Poppins';;
}

.center-bod {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin: 0;
}

.container {
  align-items: center;
  background-color: #1D7D814D;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5%;
}

.buttons-nav {
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-container {
  text-align: center;
}

.button1, .button2 {
  background-color: #213458;
  color: white;
  height: 80px;
  text-decoration: none;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 33px;
  width: 600px;
  position: relative;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
  opacity: 1;
}

.button1:hover, .button2:hover, .button-label:hover {
  opacity: 0.6;
}

.button-record-transaction {
  background-color: #1D7D81;
  color: white;
  height: 60px;
  text-decoration: none;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 25px;
  position: relative;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  opacity: 1;
}


.button-record-transaction:active {
  background-color: red; /* Change to the desired color */
  color: white; /* Text color (if needed) */
}

.button-record-transaction:hover {
  opacity: 0.8;
}

.button-label {
  display: inline-block;
  opacity: 1;
  padding: 10px 20px;
  background-color: #1D7D81; /* Default button background color */
  color: #fff; /* Default text color */
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

/* Style the label when the checkbox is checked */
.button-label input[type="checkbox"]:checked + label {
  background-color: #ff5722; /* Change the background color when checked */
}


table, th, td, thead, tr {
  border: 1px solid black;
  font-size: 30px;
  margin: auto;
  max-width: max-content;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4.3%;
  color: white;
  text-align: center;
}