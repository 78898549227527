.cashiering-body {
    margin-right:  10px;
    max-height: 715px; /* Adjust the max-height as needed */
    overflow-y: auto;
  background-color: white;

}



